import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import ListItemLink from '../base/ListItemLink';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import { Box } from '@3pc/layout-components-react';
import { footerHeight } from '../components/Footer';
import { headerHeight } from '../components/PageHeader';
import { sortNums } from '../utils';

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "sublists" } }) {
      nodes {
        childMdx {
          frontmatter {
            slug
            title
            mainCategoryNumber
            categoryNumber
            sortNumber
          }
        }
      }
    }
  }
`;

const ListPage = ({ data }) => {
  return (
    <>
      <PageHeader
        headerTitle={'Die Hessische Verfassung'}
        pageType={'list-page'}
      />
      <Layout>
        <Box
          as="nav"
          id="headerNavTitle"
          pl="20px"
          pb="20px"
          mb={footerHeight.footerMobile}
          mt={headerHeight.headerMobile}
        >
          <p id="headerNavTitle" className="sr-only">
            Artikel Navigation
          </p>
          <Box as="ol" pt={['22px', '31px']} margin={['0', '0 15% 0 15%']}>
            {data.allFile.nodes
              .sort((a, b) => {
                return sortNums(
                  a.childMdx.frontmatter.sortNumber,
                  b.childMdx.frontmatter.sortNumber
                );
              })
              .map((file, index) => (
                <ListItemLink
                  key={`sublist-${index}`}
                  link={`/${file.childMdx.frontmatter.slug}`}
                  title={file.childMdx.frontmatter.title}
                  info={
                    file.childMdx.frontmatter.mainCategoryNumber &&
                    `${file.childMdx.frontmatter.mainCategoryNumber}. Hauptteil - Abschnitt ${file.childMdx.frontmatter.categoryNumber}`
                  }
                />
              ))}
          </Box>
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default ListPage;
